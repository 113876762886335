import axios from 'axios';
import {API_URL, BOT_ID, BRN_URL, MERCHANT_ID, TASK_ID} from "./Variables"
import {useTelegram} from "../../hooks/useTelegram";

export const getCustomerParams = async (params, code) => {
    let resp;
    const body = {
        init_data: params,
        entity: "customer",
        referral_code: code
    }

    await axios
        .post(`${BRN_URL}/service/${BOT_ID}/telegram/webapp/user`, body)
        .then(response => {
            resp = response.data;
            // console.log(resp);
        })
        .catch(error => console.log('get error', error));
    return resp
};

export const registerInAffiliate = async (customerId, tokenCustomer) => {
    let resp;
    const body = {
        merchant_id: MERCHANT_ID,
        code: customerId,
    };
    let config = {
        headers: {
            'X-Access-Token': tokenCustomer || ''
        }
    };
    await axios
        .post(`${BRN_URL}/tool/${BOT_ID}/affiliate-program/apply`, body, config)
        .then(response => {
            resp = response.data;
            // console.log(resp);
        })
        .catch(error => console.log('get error', error));
    return resp
};
export const getLoyaltyData = async (customerId, tokenCustomer) => {
    let resp;
    let config = {
        headers: {
            'X-Access-Token': tokenCustomer || ''
        }
    };
    await axios
        .get(`${BRN_URL}/tool/${BOT_ID}/affiliate-program/data?merchant_id=${MERCHANT_ID}`, config)
        .then(response => {
            resp = response.data;
            // console.log(resp);
        })
        .catch(error => {
            console.log('data.error', error.message)
            if (error.message === 'Request failed with status code 401') {
                localStorage.clear();
                const webApp = useTelegram();
                if (webApp?.showAlert) {
                    webApp.showAlert('Please clear the history of the chat and return.');
                }
                window.confirm('Please clear the history of the chat and return.');
            }
        });
    return resp
};
export const getCustomerToken = async (tokenCustomer) => {
    let resp;
    let config = {
        headers: {
            'X-Access-Token': tokenCustomer || ''
        }
    };
    await axios
        .get(`${BRN_URL}/person/get/customer?ensure=true`, config)
        .then(response => {
            resp = response.data;
            // console.log(resp);
        })
        .catch(error => console.log('get error', error));
    return resp
};

function wait(ms) {
    return new Promise( (resolve) => {setTimeout(resolve, ms)});
}

export const claimPoints = async (tokenCustomer, taskId) => {
    let resp;
    let config = {
        headers: {
            'X-Access-Token': tokenCustomer || ''
        }
    };
    await axios
        .post(`${BRN_URL}/tool/${BOT_ID}/affiliate-program/loyalty-task/${taskId}/claim`, {}, config)
        .then(response => {
            resp = response.data;
            // console.log(resp);
        })
        .catch(error => console.log('get error', error));
    return resp
};

export const claimSeveralPoints = async (tokenCustomer, quantity) => {
    let resp;
    let config = {
        headers: {
            'X-Access-Token': tokenCustomer || ''
        }
    };

    const requests = Array.from(Array(quantity), (async (x) =>
        await axios.post(`${BRN_URL}/tool/${BOT_ID}/affiliate-program/loyalty-task/${TASK_ID}/claim`, {}, config)));

    await axios.all(requests)
        .then(axios.spread((...responses) => {
            resp = responses.map(item => item.data);
            // console.log(resp);
        }))
        .catch(error => console.log('get error', error));
    return resp
};
export const getPointsLimits = async (tokenCustomer) => {
    let resp;

    await axios
        .get(`${BRN_URL}/tool/${BOT_ID}/affiliate-program/loyalty-task/${TASK_ID}?access_token=${tokenCustomer}`)
        .then(response => {
            resp = response?.data;
            // console.log(resp);
        })
        .catch(error => console.log('get error', error));
    return resp
};
export const getTgToken = async (userData) => {
    let resp;
    let config = {
        headers: {
            'Access-Control-Allow-Origin': '*'
        }
    }
    let body = {
        initData: userData
    }
    await axios
        .post(`${API_URL}/customer`, body, config)
        .then(response => {
            resp = response.data;
            // console.log(resp);
        })
        .catch(error => console.log('get error', error));
    return resp
};

export const getTasks = async () => {
    let resp;
    const token = localStorage.getItem('token');
    let config = {
        params: {
            limit: 300,
            offset: 0,
            merchant_id: MERCHANT_ID
        },
        headers: {
            'X-Access-Token': token
        }
    }

    await axios
        .get(`${BRN_URL}/tool/${BOT_ID}/affiliate-program/loyalty-tasks`, config)
        .then(response => {
            resp = response.data;
            // console.log(resp);
        })
        .catch(error => console.log('get error', error));
    return resp
};

export const setInteraction = async (link) => {
    let resp;
    const token = localStorage.getItem('token');
    let config = {
        params: {
            limit: 300,
            offset: 0,
            merchant_id: MERCHANT_ID
        },
        headers: {
            'X-Access-Token': token
        }
    }
    const body = {
        "type": "link-visited",
        "data": {
            "link": link
        }
    }

    await axios
        .post(`${BRN_URL}/tool/${BOT_ID}/interaction-tracker/track`, body, config)
        .then(response => {
            resp = response.data;
            // console.log(resp);
        })
        .catch(error => console.log('get error', error));
    return resp
};

export const getEarnings = async () => {
    let resp;
    const token = localStorage.getItem('token');
    let config = {
       params: {
          limit: 300,
          offset: 0,
          merchant_id: MERCHANT_ID,
          status: 'pending_approvement',
          type: 'withdraw',
        },
        headers: {
            'X-Access-Token': token
        }
    }

    await axios
        .get(`${BRN_URL}/tool/${BOT_ID}/affiliate-program/transactions`, config)
        .then(response => {
            resp = response.data;
            // console.log(resp);
        })
        .catch(error => console.log('get error', error));
    return resp
};
export const getReferrals = async () => {
    let resp;
    const token = localStorage.getItem('token');
    let config = {
        params: {
            limit: 500,
            offset: 0,
        },
        headers: {
            'X-Access-Token': token
        }
    }
    await axios
        .get(`${BRN_URL}/tool/${BOT_ID}/affiliate-program/referrals`, config)
        .then(response => {
            resp = response.data;
            // console.log(resp);
        })
        .catch(error => console.log('get error', error));
    return resp
};

export const getGameReward = async (id) => {
    let resp;
    const token = localStorage.getItem('token');
    let config = {
        headers: {
            'X-Access-Token': token
        }
    }

    let body = id ? {
        drop_id: id
    } : {};

    await axios
        .post(`${BRN_URL}/tool/${BOT_ID}/click-game/click`, body, config)
        .then(response => {
            resp = response.data;
            // console.log(resp);
        })
        .catch(error => console.log('get error', error));
    return resp
};

export const getNextBall = async () => {
    let resp;
    const token = localStorage.getItem('token');
    let config = {
        headers: {
            'X-Access-Token': token
        }
    }

    await axios
        .get(`${BRN_URL}/tool/${BOT_ID}/click-game/drop`, config)
        .then(response => {
            resp = response.data;
            // console.log(resp);
        })
        .catch(error => console.log('get error', error));
    return resp
};
export const getCollectibles = async (id) => {
    let resp;
    const token = localStorage.getItem('token');
    let config = {
        params: {
            collection_id: id
        },
        headers: {
            'X-Access-Token': token
        }
    }

    await axios
        .get(`${BRN_URL}/tool/${BOT_ID}/click-game/items`, config)
        .then(response => {
            console.log(resp);
            resp = response.data;
            // console.log(resp);
        }).catch(error => {
            console.log('get error', error);
        });
    return resp
};

export const equipItem = async (id) => {
    let resp;
    const token = localStorage.getItem('token');

    let config = {
        headers: {
            'X-Access-Token': token
        }
    }
    let body = {
      'item_id': id
    }

    await axios
        .post(`${BRN_URL}/tool/${BOT_ID}/click-game/equip`, body, config)
        .then(response => {
            resp = response.data;
            // console.log(resp);
        }).catch((e) => {
            console.log(e);
        });

    return resp
};
export const sellPaddle = async (id, quantity) => {
    let resp;
    const token = localStorage.getItem('token');

    let config = {
        headers: {
            'X-Access-Token': token
        }
    }
    let body = {
      'item_id': id,
      "quantity": quantity
    }

    await axios
        .post(`${BRN_URL}/tool/${BOT_ID}/click-game/sell`, body, config)
        .then(response => {
            resp = response.data;
            // console.log(resp);
        }).catch((e) => {
            console.log(e);
        });

    return resp
};

export const getEquipment = async (id) => {
    let resp;
    const token = localStorage.getItem('token');

    let config = {
        headers: {
            'X-Access-Token': token
        }
    }

    await axios
        .get(`${BRN_URL}/tool/${BOT_ID}/click-game/equipment`, config)
        .then(response => {
            resp = response.data;
            // console.log(resp);
        }).catch((e) => {
            console.log(e);
        });

    return resp
};
export const setWalletAddress = async (walletAddress) => {
    let resp;
    const token = localStorage.getItem('token');

    let config = {
        headers: {
            'X-Access-Token': token
        }
    }
    let body = {
        address: walletAddress,
        blockchain: 'solana'
    }
    await axios
        .post(`${BRN_URL}/person/crypto-wallet`, body, config)
        .then(response => {
            resp = response.data;
            // console.log(resp);
        });
    return resp
};
export const deleteWalletAddress = async (walletAddress) => {
    let resp;
    const token = localStorage.getItem('token');

    let config = {
        headers: {
            'X-Access-Token': token
        }
    }

    await axios
        .delete(`${BRN_URL}/person/crypto-wallet`, config)
        .then(response => {
            resp = response.data;
            // console.log(resp);
        })
    return resp
};

//
export const getCustomersWallet = async () => {
    let resp;
    const token = localStorage.getItem('token');
    let config = {
        headers: {
            'X-Access-Token': token || ''
        }
    };
    await axios
        .get(`${BRN_URL}/person/me`, config)
        .then(response => {
            resp = response.data;
            // console.log(resp);
        })
        .catch(error => console.log('get error', error));
    return resp
}

export const changeCustomerData = async (body, id) => {
    let resp;
    const token = localStorage.getItem('token');
    let config = {
        headers: {
            'X-Access-Token': token || ''
        }
    };
    await axios
        .put(`${BRN_URL}/api/customer`, body, config)
        .then(response => {
            resp = response.data;
            // console.log(resp);
        })
        .catch(error => console.log('get error', error));
    return resp
}
export const changeProfile = async (body, id) => {
    let resp;
    const token = localStorage.getItem('token');
    let config = {
        headers: {
            'X-Access-Token': token || ''
        }
    };
    await axios
        .put(`${BRN_URL}/person/me`, body, config)
        .then(response => {
            resp = response.data;
            // console.log(resp);
        })
        .catch(error => console.log('get error', error));
    return resp
}


export const flySignup = async (body, id) => {
    let resp;
    await axios
        .post(`${BRN_URL}/customer/create/${BOT_ID}`, body)
        .then(response => {
            resp = response.data;
            console.log(resp);
        })
        .catch(error => console.log('get error', error));
    return resp
}

